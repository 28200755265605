import {
  BadRequestError,
  BaseError,
  ConflictError,
  ForbiddenError,
  NotFoundError,
  UnauthorizedError,
  ValidationError,
} from './fetch_api'

const useHttps = import.meta.env.VITE_API_HTTPS === 'true'
const protocol = useHttps ? 'https' : 'http'
const host = import.meta.env.VITE_API_HOST
const version = import.meta.env.VITE_API_VERSION
export const API_BASE_URL = `${protocol}://${host}/${version}`
export const apiKey = import.meta.env.VITE_API_KEY

/**
 * GETリクエストのオプションを返す
 *
 * @param headers
 * @returns request options
 */
export const getRequestOptions = (
  headers: Record<string, string> = {},
): RequestInit => ({
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
    'X-Api-Key': apiKey,
    ...headers,
  },
})

/**
 * POSTリクエストのオプションを返す
 *
 * @param body
 * @param headers
 * @returns request options
 */
export const postRequestOptions = (
  body: Record<string, unknown>,
  headers: Record<string, string> = {},
): RequestInit => ({
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    'X-Api-Key': apiKey,
    ...headers,
  },
  body: JSON.stringify(body),
})

/**
 * FormのPOSTリクエストオプションを返す
 *
 * @param body
 * @param headers
 * @returns request options
 */
export const postFormRequestOptions = (
  body: Record<string, unknown>,
  headers: Record<string, string> = {},
): RequestInit => ({
  method: 'POST',
  headers: {
    'X-Api-Key': apiKey,
    ...headers,
  },
  body: JSON.stringify(body),
})

/**
 * PUTリクエストのオプションを返す
 *
 * @param body
 * @param headers
 * @returns request options
 */
export const putRequestOptions = (
  body: Record<string, unknown>,
  headers: Record<string, string> = {},
): RequestInit => ({
  method: 'PUT',
  headers: {
    'Content-Type': 'application/json',
    'X-Api-Key': apiKey,
    ...headers,
  },
  body: JSON.stringify(body),
})

/**
 * PATCHリクエストのオプションを返す
 *
 * @param body
 * @param headers
 * @returns request options
 */
export const patchRequestOptions = (
  body: Record<string, unknown>,
  headers: Record<string, string> = {},
): RequestInit => ({
  method: 'PATCH',
  headers: {
    'Content-Type': 'application/json',
    'X-Api-Key': apiKey,
    ...headers,
  },
  body: JSON.stringify(body),
})

/**
 * DELETEリクエストのオプションを返す
 *
 * @param body
 * @param headers
 * @returns request options
 */
export const deleteRequestOptions = (
  body: Record<string, unknown> = {},
  headers: Record<string, string> = {},
): RequestInit => ({
  method: 'DELETE',
  headers: {
    'Content-Type': 'application/json',
    'X-Api-Key': apiKey,
    ...headers,
  },
  body: Object.keys(body).length ? JSON.stringify(body) : null,
})

type ErrorResponseData = {
  error:
    | string
    | {
        validation_errors: { [key: string]: string }[]
      }
  errorCode?: string
}

/**
 * BadRequestのエラーを処理する
 *
 * @param data
 * @returns void
 */
const handleBadRequest = (data: ErrorResponseData) => {
  if (typeof data.error === 'object' && data.error !== null) {
    if ('validation_errors' in data.error) {
      throw new ValidationError('Bad Request', data.error.validation_errors)
    }
  } else {
    throw new BadRequestError(data.error)
  }
}

/**
 * ステータスエラーを処理する response.okがfalseの場合に使用
 *
 * @param response
 * @returns void
 */
export const handleStatusError = async (response: Response) => {
  let data: ErrorResponseData
  try {
    data = await response.json()
  } catch (error) {
    throw new Error('Failed to parse JSON response')
  }

  switch (response.status) {
    case 400:
      handleBadRequest(data)
      break
    case 401:
      throw new UnauthorizedError(data.error as string, data.errorCode)
    case 403:
      throw new ForbiddenError(data.error as string, data.errorCode)
    case 404:
      throw new NotFoundError(data.error as string, data.errorCode)
    case 409:
      throw new ConflictError(data.error as string, data.errorCode)
    case 500:
      throw new BaseError(data.error as string, data.errorCode)
    default:
      throw new Error('Network response was not ok')
  }
}

type RequestParams = {
  endpoint: string
  queryString?: string
  options: RequestInit
}

/**
 * APIからデータを取得する
 *
 * @param requestParams
 * @returns APIから取得したデータ
 *
 * @example
 * // GET /companies?active=all にリクエストを送信する
 * const data = await fetchFromAPI<Company>({
 *  endpoint: '/companies',
 *  queryString: '?active=all',
 *  options: getRequestOptions()
 * })
 */
export const fetchFromAPI = async <T>({
  endpoint,
  queryString = '',
  options,
}: RequestParams) => {
  if (options.credentials == null) {
    options.credentials = 'include'
  }
  const response = await fetch(
    `${API_BASE_URL}${endpoint}${queryString}`,
    options,
  )
  if (!response.ok) {
    await handleStatusError(response)
  }
  const data = await response.json()
  return data as T
}
