/* eslint-disable @typescript-eslint/no-explicit-any */
export type SuccessCallback = (data: unknown) => void
export type ErrorCallback = (error: unknown) => void
export type FetchCallback = {
  success: SuccessCallback
  error: ErrorCallback
}
export type FetchOptions = {
  method: string
  headers: { [key: string]: string }
  body: unknown | null
  credentials?: RequestCredentials
  timeout?: number
  signal?: AbortSignal
}

// TODO: 各 function で timeout を設定できるようにする
export const DEFAULT_TIMEOUT = 30 * 1000

export class BaseError extends Error {
  public errorCode?: string

  constructor(message: string, errorCode?: string) {
    super(message)
    this.errorCode = errorCode
    this.name = this.constructor.name
    Error.captureStackTrace(this, this.constructor)
  }
}
export class BadRequestError extends BaseError {}
export class UnauthorizedError extends BaseError {}
export class ForbiddenError extends BaseError {}
export class NotFoundError extends BaseError {}
export class ConflictError extends BaseError {}
export class ValidationError extends BaseError {
  errors: { [key: string]: string }[]

  constructor(message: string, errors: { [key: string]: string }[]) {
    super(message)
    this.errors = errors
  }

  static {
    // biome-ignore lint/complexity/noThisInStatic: <explanation>
    this.prototype.name = 'ValidationError'
  }
}

const useHttps = import.meta.env.VITE_API_HTTPS === 'true'
const protocol = useHttps ? 'https' : 'http'
const host = import.meta.env.VITE_API_HOST
const version = import.meta.env.VITE_API_VERSION
const apiURL = `${protocol}://${host}/${version}`
const apiKey = import.meta.env.VITE_API_KEY

export function get<T>(path: string): Promise<T> {
  // TODO: 省略して書けるようにしたい
  return fetchAPI<T>(path, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    body: null,
  })
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export function post<T>(path: string, body: any): Promise<T> {
  return fetchAPI<T>(path, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export function post_form<T>(path: string, body: any): Promise<T> {
  return fetchAPI<T>(path, {
    method: 'POST',
    body: body,
  })
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export function put<T>(path: string, body: any): Promise<T> {
  return fetchAPI<T>(path, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })
}

// TODO: body も T になるのでは
// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export function patch<T>(path: string, body: any): Promise<T> {
  return fetchAPI<T>(path, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export function del<T>(path: string, body: any): Promise<T> {
  return fetchAPI<T>(path, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })
}

// TODO: リトライ機能を追加
function fetchAPI<T>(
  path: string,
  options: RequestInit,
  timeout = DEFAULT_TIMEOUT,
): Promise<T> {
  return new Promise<T>((success, error) => {
    try {
      if (options.credentials == null) {
        options.credentials = 'include'
      }
      if (apiKey) {
        options.headers = {
          ...options.headers,
          'X-Api-Key': apiKey,
        }
      }
      options.signal = AbortSignal.timeout(timeout)
      const url = path.startsWith('/')
        ? `${apiURL}${path}`
        : `${apiURL}/${path}`
      fetch(url, options)
        .then<T>(async response => {
          // TODO: いったん catch に飛ばしてるけど要検討
          if (!response.ok) {
            if (response.status === 400) {
              const json = await response.json()

              if (json?.error?.validation_errors) {
                throw new ValidationError(
                  'Bad Request',
                  json.error.validation_errors,
                )
              }
              if (json?.error) {
                throw new BadRequestError(json?.error || 'Bad Request')
              }
            }
            if (response.status === 401) {
              throw new UnauthorizedError('Unauthorized')
            }
            if (response.status === 403) {
              throw new ForbiddenError('Forbidden')
            }
            if (response.status === 404) {
              throw new NotFoundError('Not Found')
            }
            if (response.status === 409) {
              throw new ConflictError('Conflict')
            }
            if (response.status === 500) {
              throw new Error('Internal Server Error')
            }
            throw new Error('Network response was not ok')
          }
          return response.json()
        })
        .then(data => {
          success(data)
        })
        .catch((err: Error) => {
          if (err.name === 'AbortError') {
            error(
              `Timeout: It took more than ${
                (timeout || 0) / 1000
              } seconds to get the result!`,
            )
          } else {
            error(err)
          }
        })
    } catch (err) {
      error(err)
    }
  })
}
